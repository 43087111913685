export const EXTENSIONS = {
    "advanced-charts": {
        name: 'advanced-charts',
        label: "Advanced Visualizations",
        author: "Neo4j Labs",
        image: "advanced-visualizations.png",
        enabled: true,
        description: "Advanced visualizations let you take your dashboard to the next level. This extension adds a sankey chart to visualize flows, three charts to plot hierarchical data (Sunburst, Circle Packing, Treemap). A Gauge Chart to show percentages, a Radar chart to show radial data, and a Choropleth map to visualize country-data.",
        link: 'https://neo4j.com/labs/neodash/2.2/user-guide'
    },
    "rule-based-styling": {
        name: 'styling',
        label: "Rule-Based Styling",
        author: "Neo4j Labs",
        image: "rule-based-styling.png",
        enabled: true,
        description: "The rule-based styling extension allows users to dynamically color elements in a visualization based on output values. This can be applied to tables, graphs, bar charts, line charts, and more. To use the extension, click on the 'rule-based styling' icon inside the settings of a report.",
        link: 'https://neo4j.com/labs/neodash/2.2/user-guide'
    },
    "report-actions": {
        name: 'actions',
        label: "Report Actions",
        author: "Neo4j Professional Services",
        image: "report-actions.png",
        enabled: false,
        description: "Report actions let dashboard builders add extra interactivity into dashboards. For example, setting parameter values when a cell in a table or a node in a graph is clicked. To learn more about this extension, reach out to Neo4j Professional Services.",
        link: 'https://neo4j.com/professional-services/'
    }
}